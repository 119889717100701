import axios from '@axios'

export default async function fetchLogin(body) {
  try {
    return await axios.post(`${process.env.VUE_APP_API}/v2/auth`, body)
  } catch (e) {
    console.log(e.response.data)

    return e
  }
}
