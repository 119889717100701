// import { canNavigate } from '@/plugins/acl/routeProtection'
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: () => {
      const userData = JSON.parse(localStorage.getItem('login_info'))
      const userRole = userData && userData.role ? userData.role : null
      if (userRole === 'client') return { name: 'page-access-control' }

      // return { name: 'customer-list' }

      return { name: 'auth-login' }
    },
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/pages/Home.vue'),
    meta: {
      layout: 'content',
      name: 'home',
    },
  },
  {
    path: '/profile',
    name: 'mypage',
    component: () => import('@/views/pages/MyPage.vue'),
    meta: {
      layout: 'content',
      name: 'mypage',
    },
  },

  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/customerList',
    name: 'customer-list',
    component: () => import('@/views/pages/main/Customer.vue'),
    meta: {
      layout: 'content',
      resource: 'Public',
      name: 'customer',
    },
  },
  {
    path: '/customerDetail/:id',
    component: () => import('@/views/components/detail/CustomerDetailContainer.vue'),
    name: 'detail',
    props: true,
    meta: {
      layout: 'content',
      name: 'customer',
    },
  },
  {
    path: '/wrhousMvmnList/:mvmnDe/:mvmnSn',
    name: 'wrhousmvmn-list',
    component: () => import('@/views/pages/wrhousmvmn/Container.vue'),
    props: true,
    meta: {
      layout: 'content',
      resource: 'Public',
      name: 'wrhousmvmn-list',
    },
  },
  {
    path: '/locationList',
    name: 'location-list',
    component: () => import('@/views/pages/wrhouslocation/Container.vue'),
    props: true,
    meta: {
      layout: 'content',
      resource: 'Public',
      name: 'wrhousloc',
    },
  },
  {
    path: '/packingList',
    name: 'packing-list',
    component: () => import('@/views/pages/wrhouslocation/ContainerPacking.vue'),
    props: true,
    meta: {
      layout: 'content',
      resource: 'Public',
      name: 'wrhouspacking',
    },
  },
  {
    path: '/orderList/:orderId',
    name: 'order-list',
    component: () => import('@/views/pages/order/Container.vue'),
    props: true,
    meta: {
      layout: 'content',
      resource: 'Public',
      name: 'orderList',
    },
  },
  {
    path: '/releaseList/:orderId',
    name: 'release-list',
    component: () => import('@/views/pages/order/ContainerRelease.vue'),
    props: true,
    meta: {
      layout: 'content',
      resource: 'Public',
      name: 'releaseList',
    },
  },
  {
    path: '/dlvyOrderList/:orderId',
    name: 'dlvy-order-list',
    component: () => import('@/views/pages/order/ContainerDlvyOrder.vue'),
    props: true,
    meta: {
      layout: 'content',
      resource: 'Public',
      name: 'dlvyOrderList',
    },
  },
  {
    path: '/3plOrderList/:orderId',
    name: '3pl-order-list',
    component: () => import('@/views/pages/order/Container3plOrder.vue'),
    props: true,
    meta: {
      layout: 'content',
      resource: 'Public',
      name: '3plOrderList',
    },
  },
  {
    path: '/wrhousOrderList/:orderId',
    name: 'wrhous-order-list',
    component: () => import('@/views/pages/order/ContainerWrhousOrder.vue'),
    props: true,
    meta: {
      layout: 'content',
      resource: 'Public',
      name: 'wrhousOrderList',
    },
  },
  {
    path: '/csOrderList/:orderId',
    name: 'cs-order-list',
    component: () => import('@/views/pages/order/ContainerCsOrder.vue'),
    props: true,
    meta: {
      layout: 'content',
      resource: 'Public',
      name: 'csOrderList',
    },
  },
  {
    path: '/wrhousnoOrderList/:orderId/:itemNo',
    name: 'wrhousno-order-list',
    component: () => import('@/views/pages/order/ContainerWrhousnoOrder.vue'),
    props: true,
    meta: {
      layout: 'content',
      resource: 'Public',
      name: 'wrhousnoOrderList',
    },
  },
  {
    path: '/nopayOrderList/:orderId',
    name: 'nopay-order-list',
    component: () => import('@/views/pages/order/ContainerNopayOrder.vue'),
    props: true,
    meta: {
      layout: 'content',
      resource: 'Public',
      name: 'nopayOrderList',
    },
  },
  {
    path: '/prdlstList',
    name: 'prdlst-list',
    component: () => import('@/views/pages/prdlst/Container.vue'),
    props: true,
    meta: {
      layout: 'content',
      resource: 'Public',
      name: 'prdlst',
    },
  },
  {
    path: '/prdlstStockList',
    name: 'prdlststock-list',
    component: () => import('@/views/pages/prdlst/ContainerStock.vue'),
    props: true,
    meta: {
      layout: 'content',
      resource: 'Public',
      name: 'prdlst',
    },
  },
  {
    path: '/douzonPrdlstList',
    name: 'douzon-prdlst-list',
    component: () => import('@/views/pages/prdlst/ContainerDouzon.vue'),
    props: true,
    meta: {
      layout: 'content',
      resource: 'Public',
      name: 'douzon-prdlst',
    },
  },
  {
    path: '/slebcncstatList',
    name: 'slebcncstat-list',
    component: () => import('@/views/pages/slemng/Container.vue'),
    props: true,
    meta: {
      layout: 'content',
      resource: 'Public',
      name: 'slemng',
    },
  },
  {
    path: '/ttoocmpnyList',
    name: 'ttoocmpny-list',
    component: () => import('@/views/pages/slemng/ContainerTtoo.vue'),
    props: true,
    meta: {
      layout: 'content',
      resource: 'Public',
      name: 'slemng',
    },
  },
  {
    path: '/kpiList',
    name: 'kpi-list',
    component: () => import('@/views/pages/rsltmng/ContainerKPI.vue'),
    props: true,
    meta: {
      layout: 'content',
      resource: 'Public',
      name: 'rsltmng',
    },
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('@/views/pages/Error404.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '*',
    redirect: 'error',
  },
  {
    path: '/version',
    name: 'version',
    component: () => import('@/views/pages/Version.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    }
  },
})
const resetBody = () => {
  const searchBody = {
    color_code: '',
    color_name: '',
    car_type: '',
    variation_code: '',
    first_condition: 'or',
    second_condition: 'or',
    third_condition: 'or',
    item_per_page: 20,
    page_index: 1,
  }
  localStorage.setItem(
    'search_body',
    JSON.stringify({
      tableName: 'none',
      body: searchBody,
    }),
  )
}
router.beforeEach((to, _, next) => {
  // const userData = localStorage.getItem('userData')

  const isLoggedIn = localStorage.getItem('access_token')
  console.log(to)

  /*
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // resetBody()

    // If logged in => not authorized
    // return next({ name: 'misc-not-authorized' })
  }
   */
  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next({ name: 'home' })
  }

  return next()
})

const searchLocalStorage = to => {
  const storageBody = JSON.parse(localStorage.getItem('search_body'))
  const nextPageName = to.meta.name

  if (storageBody && nextPageName !== storageBody.tableName.toLowerCase()) {
    console.log('------------')
    console.log(to)
    console.log(nextPageName)
    console.log(storageBody.tableName)

    if (nextPageName === undefined && storageBody.tableName) {
      console.log('undefined KMJJJJJ')
      resetBody()
    } else {
      resetBody()
    }
  } else if (nextPageName === 'home') {
    // console.log(storageBody)
    resetBody()
  }
}

router.afterEach(to => {
  searchLocalStorage(to)
})
export default router
