<template>
  <v-menu
    content-class="user-profile-menu-content"
    left
    min-width="230"
    nudge-bottom="14"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        bottom
        class="ms-4"
        color="success"
        dot
        offset-x="12"
        offset-y="12"
        overlap
      >
        <v-avatar
          class="v-avatar-light-bg primary--text"
          color="primary"
          size="40px"
          v-bind="attrs"
          v-on="on"
        >
          <v-img :src="picture"></v-img>
        </v-avatar>
      </v-badge>
    </template>
    <v-list>
      <div class="pb-3 pt-2">
        <v-badge
          bottom
          class="ms-4"
          color="success"
          dot
          offset-x="12"
          offset-y="12"
          overlap
        >
          <v-avatar
            class="v-avatar-light-bg primary--text"
            color="primary"
            size="40px"
          >
            <v-img
              :src="picture"
              rel="noreferrer"
            ></v-img>
          </v-avatar>
        </v-badge>
        <div
          class="d-inline-flex flex-column justify-center ms-3"
          style="vertical-align:middle"
        >
          <span class="text--primary font-weight-semibold mb-n1">
            {{ name }}
          </span>
          <small class="text--disabled text-capitalize">{{ role }}</small>
        </div>
      </div>

      <v-divider class="my-2"></v-divider>

      <!--       Profile -->
      <v-list-item @click="goMyPage">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiAccountOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Profile</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Chat -->
      <!--      <v-list-item href="#">-->
      <!--        <v-list-item-icon class="me-2">-->
      <!--          <v-icon size="22">-->
      <!--            {{ icons.mdiChatOutline }}-->
      <!--          </v-icon>-->
      <!--        </v-list-item-icon>-->
      <!--        <v-list-item-content>-->
      <!--          <v-list-item-title>Chat</v-list-item-title>-->
      <!--        </v-list-item-content>-->

      <!--        <v-list-item-action>-->
      <!--          <v-badge-->
      <!--            color="error"-->
      <!--            content="2"-->
      <!--            inline-->
      <!--          >-->
      <!--          </v-badge>-->
      <!--        </v-list-item-action>-->
      <!--      </v-list-item>-->

      <!--      <v-divider class="my-2"></v-divider>-->

      <!-- Settings -->
      <!--      <v-list-item href="#">-->
      <!--        <v-list-item-icon class="me-2">-->
      <!--          <v-icon size="22">-->
      <!--            {{ icons.mdiCogOutline }}-->
      <!--          </v-icon>-->
      <!--        </v-list-item-icon>-->
      <!--        <v-list-item-content>-->
      <!--          <v-list-item-title>Settings</v-list-item-title>-->
      <!--        </v-list-item-content>-->
      <!--      </v-list-item>-->

      <!-- Pricing -->
      <!--      <v-list-item href="#">-->
      <!--        <v-list-item-icon class="me-2">-->
      <!--          <v-icon size="22">-->
      <!--            {{ icons.mdiCurrencyUsd }}-->
      <!--          </v-icon>-->
      <!--        </v-list-item-icon>-->
      <!--        <v-list-item-content>-->
      <!--          <v-list-item-title>Pricing</v-list-item-title>-->
      <!--        </v-list-item-content>-->
      <!--      </v-list-item>-->

      <!-- FAQ -->
      <!--      <v-list-item href="#">-->
      <!--        <v-list-item-icon class="me-2">-->
      <!--          <v-icon size="22">-->
      <!--            {{ icons.mdiHelpCircleOutline }}-->
      <!--          </v-icon>-->
      <!--        </v-list-item-icon>-->
      <!--        <v-list-item-content>-->
      <!--          <v-list-item-title>FAQ</v-list-item-title>-->
      <!--        </v-list-item-content>-->
      <!--      </v-list-item>-->

      <!--      <v-divider class="my-2"></v-divider>-->

      <!-- Logout -->
      <v-list-item
        @click="logout"
      >
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { useRouter } from '@core/utils'
import {
  mdiAccountCogOutline,
  mdiAccountOutline,
  mdiChatOutline,
  mdiCheckboxMarkedOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
} from '@mdi/js'

import store from '@/store'

export default {
  setup() {
    const { router } = useRouter()

    const logout = async () => {
      localStorage.removeItem('access_token')

      await store.commit('app/FETCH_LOGOUT', true)
      await router.replace('/')
    }

    const checkLogin = () => {
      if (localStorage.getItem('login_info') === 'undefined' || localStorage.getItem('login_info') === null) {
        logout()
      }
    }
    checkLogin()
    const { name, picture, role } = JSON.parse(localStorage.getItem('login_info'))

    const { user_uuid } = JSON.parse(localStorage.getItem('login_info'))
    const goMyPage = () => {
      router.push('/profile')
    }

    return {
      logout,
      goMyPage,
      name,
      picture,
      role,
      user_uuid,
      icons: {
        mdiAccountOutline,
        mdiAccountCogOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
      },
    }
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
