import getCustomerCount from '@/api/home'
import auth from './auth'
import customer from './customer'
import dialog from './dialog'
import search from './search'

export default {
  namespaced: true,
  modules: {
    auth,
    dialog,
    search,

    customer,
  },
  state: {
    shallContentShowOverlay: false,
    pageTitle: '',
    labels: [],
    customerCount: [],
    popularBookmarks: [],
    popularLikes: [],
    congrats: [],
    recentItem: {
      color_name: '-',
      color_code: '-',
      variation_code: '-',
      paint_type: '-',
      supplier: 'default',
    },
    update: '',
    orderStatusItems: [
      { value: 'C00', text: '취소신청' },
      { value: 'C34', text: '취소처리중' },
      { value: 'C40', text: '취소완료' },
      { value: 'C47', text: '입금전취소' },
      { value: 'C49', text: '입금전취소' },
      { value: 'E00', text: '교환신청' },
      { value: 'E30', text: '교환처리중' },
      { value: 'E34', text: '교환처리중' },
      { value: 'E40', text: '교환완료' },
      { value: 'E41', text: '교환완료' },
      { value: 'N00', text: '입금전' },
      { value: 'N20', text: '배송준비중' },
      { value: 'N21', text: '배송대기' },
      { value: 'N22', text: '배송보류' },
      { value: 'N30', text: '배송중' },
      { value: 'N40', text: '배송완료' },
      { value: 'N50', text: '구매확정' },
      { value: 'R00', text: '반품신청' },
      { value: 'R30', text: '반품처리중' },
      { value: 'R40', text: '반품완료' },
    ],
    schSrchDateTypeItems: [
      { value: 'o.order_date', text: '주문일' },
      { value: 'o.payment_date', text: '결제일' },
      { value: 'oto.shipped_date', text: '배송시작일' },
      { value: 'oto.delivered_date', text: '배송완료일' },
      { value: 'oto.cancel_date', text: '주문취소일' },
      { value: 'oto.cancel_request_date', text: '취소신청일' },
      { value: 'oto.cancel_date', text: '취소완료일' },
      { value: 'oto.exchange_request_date', text: '교환신청일' },
      { value: 'oto.return_request_date', text: '반품신청일' },
      { value: 'oto.return_confirmed_date', text: '반품접수일' },
      { value: 'oto.return_collected_date', text: '반품완료일' },
    ],
    dlvyCmpnyItems: [
      { value: '43', text: '대신택배' },
      { value: '18', text: '한진택배' },
      { value: '19', text: '롯데택배' },
      { value: '1060', text: '롯데택배(연동)' },
      { value: '39', text: '경동택배' },
      { value: '4', text: '로젠택배' },
      { value: '6', text: 'CJ대한통운' },
      { value: '13', text: '우체국(등기,소포 우편)' },
      { value: '1', text: '직배송(자체배송)' },
    ],
    classNmList: [
      { text: '공업사', value: '공업사' },
      { text: '외형복원', value: '외형복원' },
      { text: '페인트/자재가게', value: '페인트/자재가게' },
      { text: '경정비', value: '경정비' },
      { text: '제조사/수입사', value: '제조사/수입사' },
      { text: '세차및디테일링', value: '세차및디테일링' },
      { text: '조형물', value: '조형물' },
      { text: '교육기관', value: '교육기관' },
      { text: '일반회원', value: '일반회원' },
      { text: '기타사업자', value: '기타사업자' },
      { text: '미정', value: '미정' },
    ],
    prdlstChargerItems: [
      { value: '14769642-08fd-4607-978b-3008b185f78b', text: '김동영' },
      { value: 'cfc916c7-6e6e-4ca3-b2c7-4e88077bf772', text: '김현식' },
      { value: 'e7b773d1-483a-416a-87ff-8ed7be05ced0', text: '성민주' },
      { value: 'b2d87749-6f85-49a5-b191-459aa2d2fbc8', text: '김상우' },
    ],
    sleSttusItems: [
      { value: '', text: '정상' },
      { value: '1000', text: '폐업' },
      { value: '2000', text: '일시정지' },
      { value: '3000', text: '지점변경' },
    ],
    entrpsItems: [
      { value: '9', text: '삼조케미칼(주)' },
      { value: '29', text: '주식회사 탑건' },
      { value: '19', text: '(주)에어팩토리' },
      { value: '25', text: '무한상사' },
      { value: '17', text: '선재교역' },
      { value: '45', text: '(주)액트네트웍스' },
      { value: '44', text: '주식회사 유한킨포크' },
      { value: '51', text: '(주)제이엠씨엔지니어링' },
      { value: '14', text: '로이스기계주식회사' },
      { value: '54', text: '티에스툴' },
      { value: '52', text: '디바텍' },
      { value: '38', text: '(주)조영' },
      { value: '56', text: '(주)프로픽스' },
      { value: '8', text: '와이에스 솔루션' },
      { value: '11', text: '(주)나비엠알오' },
      { value: '13', text: '주식회사 카닥' },
      { value: '20', text: '(주)경도피엔피' },
      { value: '30', text: '위드컬러' },
      { value: '31', text: '주식회사 평택현대페인트 원주지점' },
      { value: '32', text: '조은도료' },
      { value: '37', text: '(주)휴먼텍' },
      { value: '43', text: '주식회사 채움인터네셔널' },
      { value: '48', text: '가현종합물산' },
      { value: '49', text: '천우프로토피아' },
      { value: '53', text: '글로다온' },
      { value: '57', text: '주식회사 굿즈코리아' },
      { value: '59', text: '제이엔씨(주)' },
      { value: '61', text: '제이엔씨(주)' },
      { value: '16', text: '(유)피피지코리아' },
      { value: '5', text: '주식회사 프로트레이딩' },
      { value: '33', text: '주식회사 탑건' },
      { value: '58', text: '백호상사' },
      { value: '60', text: '백호상사' },
      { value: '40', text: '오성정밀화학(주)' },
      { value: '4', text: '원진통상' },
      { value: '2', text: '주식회사 프로툴' },
      { value: '76', text: '태양통상' },
      { value: '77', text: '(주)유알오' },
      { value: '78', text: '조광페인트(주)' },
      { value: '79', text: '성원산업' },
      { value: '80', text: '유니온플러스' },
      { value: '81', text: '디앤에프(D&F)' },
      { value: '82', text: '(주)대명연마' },
      { value: '83', text: '노루시켄스 SIKKENS (1팀)' },
      { value: '84', text: '(주)노루페인트 1팀' },
      { value: '85', text: '한국아이씨엠(주)' },
      { value: '86', text: '광성케미칼（주）' },
      { value: '87', text: '（주）피닉스엔덕수' },
      { value: '88', text: '(주)호계' },
      { value: '89', text: '주식회사 드림' },
      { value: '90', text: '네이버파이낸셜 주식회사' },
      { value: '91', text: '주식회사 제이메라크' },
      { value: '92', text: '예당케미칼' },
      { value: '93', text: '(주)오토그루밍' },
      { value: '94', text: '신합천' },
      { value: '95', text: '주식회사 제이엠앤에스(JMNS Co.Ltd.,)' },
      { value: '96', text: '(주)서진피앤씨' },
      { value: '97', text: '주식회사 피에스산업' },
      { value: '98', text: '주식회사 록키' },
      { value: '99', text: '(주)건화상사' },
      { value: '100', text: '태신무역주식회사' },
      { value: '101', text: '세진인쇄' },
      { value: '102', text: '주식회사 천우페인트솔루션' },
      { value: '103', text: '재승상사' },
      { value: '104', text: '주식회사보성케미칼' },
      { value: '105', text: '하나상사' },
      { value: '106', text: '주식회사 DT' },
      { value: '107', text: '한국제일부직포' },
      { value: '108', text: '새론종합상사' },
      { value: '109', text: '한일상사' },
      { value: '110', text: '용호 브러쉬' },
      { value: '111', text: '（주）엠에스코리아트레이딩' },
      { value: '112', text: '원주현대페인트' },
      { value: '113', text: '아리랑공구(주)' },
      { value: '50', text: '주식회사 엘엑스제이' },
      { value: '42', text: '아시아종합기계 (주)' },
      { value: '55', text: '경화상사' },
      { value: '10', text: '강남제비스코(주)' },
      { value: '27', text: '(주)오토' },
      { value: '21', text: '와튼' },
      { value: '26', text: '마이애미커스텀' },
      { value: '3', text: '바이켐(주)' },
      { value: '41', text: '주식회사 운연공업사' },
      { value: '47', text: '주식회사 팀에스티 (Teamst CO.,LTD.)' },
      { value: '28', text: '정진하이테크' },
      { value: '6', text: '주식회사 대성오토' },
      { value: '12', text: '(주)창성이스코' },
      { value: '34', text: '성남기공' },
      { value: '24', text: '백호상사' },
      { value: '1', text: '주식회사 프로트레이딩' },
      { value: '18', text: '한국쓰리엠(주)' },
      { value: '39', text: '(주)삼부ATC' },
      { value: '15', text: '동광상사' },
      { value: '35', text: '(주)네오스엠' },
      { value: '36', text: '영림화학(주)' },
      { value: '46', text: '우신AIRPRO' },
      { value: '22', text: '원진통상' },
      { value: '7', text: '(주)케이씨씨 서울영업소' },
      { value: '23', text: '에코런(주)' },
      { value: '114', text: '오토워시스토어' },
      { value: '115', text: '로마스' },
    ],
    sigunItems: [
      { value: '강남구', text: '강남구' },
      { value: '강동구', text: '강동구' },
      { value: '강북구', text: '강북구' },
      { value: '강서구', text: '강서구' },
      { value: '관악구', text: '관악구' },
      { value: '광진구', text: '광진구' },
      { value: '구로구', text: '구로구' },
      { value: '금천구', text: '금천구' },
      { value: '노원구', text: '노원구' },
      { value: '도봉구', text: '도봉구' },
      { value: '동작구', text: '동작구' },
      { value: '마포구', text: '마포구' },
      { value: '서초구', text: '서초구' },
      { value: '성동구', text: '성동구' },
      { value: '성북구', text: '성북구' },
      { value: '송파구', text: '송파구' },
      { value: '양천구', text: '양천구' },
      { value: '용산구', text: '용산구' },
      { value: '은평구', text: '은평구' },
      { value: '중랑구', text: '중랑구' },
      { value: '동대문구', text: '동대문구' },
      { value: '서대문구', text: '서대문구' },
      { value: '영등포구', text: '영등포구' },
    ],
  },
  getters: {},
  mutations: {
    TOGGLE_CONTENT_OVERLAY(state, value) {
      state.shallContentShowOverlay = value !== undefined ? value : !state.shallContentShowOverlay
    },
    GET_UPDATE_AT(state, value) {
      state.update = value
    },
    GET_STATISTICS(state, value) {
      state.customerCount = value
    },
    SET_PAGETITLE(state, value) {
      state.pageTitle = value
    },
  },
  actions: {
    async GET_STATISTICS({ commit }) {
      const res = await getCustomerCount()

      commit('GET_STATISTICS', res.data.data.total_items)
    },
  },
}
