// eslint-disable-next-line object-curly-newline
import {
  mdiHomeOutline,
  mdiNumeric1Box,
  mdiNumeric2Box,
  mdiNumeric3Box,
  mdiNumeric4Box,
  mdiNumeric5Box,
  mdiNumeric6Box,
} from '@mdi/js'

export default [
  {
    title: 'Home',
    icon: mdiHomeOutline,
    to: 'home',
  },
  {
    title: '회원관리',
    icon: mdiNumeric1Box,
    to: 'customer-list',
    children: [
      {
        name: 'customer-list',
        title: '온라인 회원',
        to: 'customer-list',
      },
    ],
  },
  {
    title: '물류관리',
    icon: mdiNumeric2Box,
    to: 'wrhousmvmn-list',
    children: [
      {
        title: '창고이동현황',
        to: 'wrhousmvmn-list',
      },
      {
        title: '로케이션관리',
        to: 'location-list',
      },
    ],
  },

  {
    title: '주문관리',
    icon: mdiNumeric3Box,
    children: [
      {
        title: '출고접수주문관리',
        to: 'order-list',
      },

      {
        title: '직송주문관리',
        to: 'dlvy-order-list',
      },
      {
        title: '입점주문관리',
        to: '3pl-order-list',
      },

      {
        title: '미출주문관리',
        to: 'wrhousno-order-list',
      },

      {
        title: '출고완료관리',
        to: 'wrhous-order-list',
      },

      {
        title: 'CS주문관리',
        to: 'cs-order-list',
      },
      {
        title: '입금전주문',
        to: 'nopay-order-list',
      },

      /*  {
        title: 'A/S 주문관리',
        to: 'as-order-list',
      },
      */
    ],
  },

  {
    title: '품목관리',
    icon: mdiNumeric4Box,
    to: 'prdlst-list',
    children: [
      {
        title: '온라인품목관리',
        to: 'prdlst-list',
      },
      {
        title: '지점별재고조회',
        to: 'prdlststock-list',
      },
      {
        title: '더존품목관리',
        to: 'douzon-prdlst-list',
      },
    ],
  },
  {
    title: '영업관리',
    icon: mdiNumeric5Box,
    to: 'slebcncstat-list',
    children: [
      {
        title: '거래처영업현황',
        to: 'slebcncstat-list',
      },
      {
        title: '자동차검사정비사업조합',
        to: 'ttoocmpny-list',
      },
    ],
  },
  {
    title: '성과관리',
    icon: mdiNumeric6Box,
    to: 'kpi-list',
    children: [
      {
        title: 'KPI관리',
        to: 'kpi-list',
      },
    ],
  },
]
