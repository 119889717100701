import fetchTable, { fetchTableCustom } from '@/api/search'

const body = {
  first_condition: 'or',
  second_condition: 'or',
  third_condition: 'or',
  item_per_page: 50,
  page_index: 1,
  sort_is_ascend: true,
  schName: '',
  schShopName: '',
  schClassNm: '',
  schKwd: '',
  schParams: {},
}
export default {
  state: {
    namespaced: true,
    searchBody: { ...body },
    tableData: {},
    tableCustomData: {},
    searchCustomBody: {},
    blockSnackbar: false,
    isDataLoading: false,
  },
  mutations: {
    FETCH_SEARCH_BODY(state, value) {
      state.isDataLoading = true
      state.searchBody[value.key] = value.value
      if (state.searchBody[value.key] === undefined) {
        state.searchBody.schParams[value.key] = value.value
      }
    },
    FETCH_TABLE(state, value) {
      state.tableData = value
      state.isDataLoading = false
    },
    FETCH_CUSTOM_TABLE(state, value) {
      state.tableCustomData = value
      state.isDataLoading = false
    },
    FETCH_ALL_BODY(state, value) {
      state.searchBody = value
    },
    FETCH_SEARCH_CUSTOM_BODY(state, value) {
      Object.assign(state.searchCustomBody, value)
      console.log(state.searchCustomBody)
    },
    RESET_BODY(state) {
      state.searchBody = { ...body }
    },
    ACCESS_FAIL(state, value) {
      state.blockSnackbar = value
      console.log('fail')
    },
  },
  actions: {
    // eslint-disable-next-line consistent-return
    async FETCH_SEARCH({ commit, dispatch }, { tableName, searchBody }) {
      const res = await fetchTable(tableName, searchBody)
      try {
        return commit('FETCH_TABLE', res.data.data)
      } catch (e) {
        if (res.response) {
          if (res.response.status === 403) {
            return dispatch('SET_EXPIRED', res)
          }
          if (res.response.status === 401) {
            return dispatch('FORBID_ACCESS', res)
          }
          if (res.response.status === 999) {
            return dispatch('ACCESS_FAIL', res)
          }
        }
      }
    },
    async FETCH_SEARCH_CUSTOM({ commit, dispatch }, { tableName, searchBody, actionName }) {
      const res = await fetchTableCustom(tableName, searchBody, actionName)
      try {
        return commit('FETCH_CUSTOM_TABLE', res.data.data)
      } catch (e) {
        if (res.response) {
          if (res.response.status === 403) {
            return dispatch('SET_EXPIRED', res)
          }
          if (res.response.status === 401) {
            return dispatch('FORBID_ACCESS', res)
          }
          if (res.response.status === 999) {
            return dispatch('ACCESS_FAIL', res)
          }
        }
      }

      return res
    },
  },
}
