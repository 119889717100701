export default {
  state: {
    namespaced: true,
    isAlert: {
      branch_name: false,
      created_by: false,
    },
    isReadSnackbar: false,
    isHistorySnackbar: '',
    updating: '',
    currentIdxArray: [0, 0],
  },
  getters: {
    currentIdx: state => {
      const arr = state.currentIdxArray

      return (arr[1] * 3) + arr[0]
    },
  },
  mutations: {
    FETCH_ALERT(state, value) {
      state.isAlert[value.key] = value.value
    },
    TOGGLE_UPDATE_SNACKBAR(state, value) {
      state.isReadSnackbar = value
    },
    TOGGLE_HISTORY_SNACKBAR(state, value) {
      state.isHistorySnackbar = value
    },
    SHOW_HISTORY_MODEL(state, value) {
      state.updating = value
    },
    CHANGE_INDEX(state, value) {
      console.log(value)
      state.currentIdxArray = value
    },
  },
  actions: {},
}
