import { mdiCheckboxBlankCircleOutline } from '@mdi/js'

const themeConfig = {
  app: {
    name: 'OMS',
    logo: require('@/assets/images/svg/logo.png'),
    isDark: false,
    isRtl: false,
    contentLayoutNav: 'horizontal', // vertical, horizontal
    routeTransition: 'scroll-x-transition', // https://vuetifyjs.com/en/styles/transitions/#api
    // ! `semi-dark` isn't available for horizontal nav menu
    skinVariant: 'default', // default, bordered, semi-dark
    contentWidth: 'full',
  },
  menu: {
    isMenuHidden: false,
    isVerticalNavMini: false,
    verticalMenuAccordion: true,
    groupChildIcon: mdiCheckboxBlankCircleOutline,
    horizontalNavMenuGroupOpenOnHover: true,
  },
  appBar: {
    /*
    ! In Content Layout Horizontal Nav type `hidden` value won't work
    ! In Content Layout Horizontal Nav type value of `type` will affect both Appbar and Horizontal Navigation Menu
    */
    type: 'fixed', // fixed, static, hidden
    isBlurred: true,
  },
  footer: {
    type: 'static', // fixed, static, hidden
  },
  themes: {
    light: {
      primary: '#110D4F',
      accent: '#0d6efd',
      secondary: '#8A8D93',
      success: '#56CA00',
      info: '#16B1FF',
      warning: '#FFB400',
      error: '#FF4C51',
      lightblue: '#14c6FF',
      yellow: '#FFCF00',
      pink: '#FF1976',
      orange: '#f2bd77',
      magenta: '#C33AFC',
      darkblue: '#1E2D56',
      gray: '#909090',
      neutralgray: '#9BA6C1',
      green: '#2ED47A',
      red: '#FF5c4E',
      darkblueshade: '#308DC2',
      lightgray: '#BDBDBD',
      lightpink: '#FFCFE3',
      white: '#FFFFFF',
    },
    dark: {
      primary: '#110D4F',
      accent: '#0d6efd',
      secondary: '#8A8D93',
      success: '#56CA00',
      info: '#16B1FF',
      warning: '#FFB400',
      error: '#FF4C51',
      lightblue: '#14c6FF',
      yellow: '#FFCF00',
      pink: '#FF1976',
      orange: '#A86D3E',
      magenta: '#C33AFC',
      darkblue: '#1E2D56',
      gray: '#909090',
      neutralgray: '#9BA6C1',
      green: '#2ED47A',
      red: '#FF5c4E',
      darkblueshade: '#308DC2',
      lightgray: '#BDBDBD',
      lightpink: '#FFCFE3',
      white: '#FFFFFF',
    },
  },
}

export default themeConfig
