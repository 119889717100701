import axios from '@axios'

export default async function fetchTable(tableName, body) {
  try {
    localStorage.setItem(
      'search_body',
      JSON.stringify({
        tableName,
        body,
      }),
    )

    return await axios.post(`${process.env.VUE_APP_API}/v2/${tableName.toLowerCase()}/list`, body)
  } catch (e) {
    const res = { response: { status: '999' } }

    return res
  }
}

export async function fetchTableDetail(tableName, memberId) {
  try {
    return await axios.get(`${process.env.VUE_APP_API}/v2/${tableName.toLowerCase()}`, { params: { id: memberId } })
  } catch (e) {
    console.log(e.message)

    return e
  }
}

export async function fetchTableCustom(tableName, body, actionName) {
  try {
    localStorage.setItem(
      'search_body',
      JSON.stringify({
        tableName,
        body,
      }),
    )

    return await axios.post(
      `${process.env.VUE_APP_API}/v2/${tableName.toLowerCase()}/${actionName.toLowerCase()}`,
      body,
    )
  } catch (e) {
    const res = { response: { status: '999' } }

    return res
  }
}
