import fetchLogin from '@/api/auth'
import router from '@/router'

export default {
  state: {
    logoutAlert: false,
    loginBody: {},
    authExpired: false,
    expiredAlert: false,
    loginErrorAlert: false,
  },
  getters: {},
  mutations: {
    FETCH_LOGIN_INFO(state, value) {
      state.loginBody = value
    },
    FETCH_LOGOUT(state, value) {
      if (state.authExpired) {
        state.expiredAlert = value
      } else {
        state.logoutAlert = value
      }
    },
    FETCH_LOGIN_ERROR(state, value) {
      state.loginErrorAlert = value
    },
    FETCH_EXPIRED(state, value) {
      state.authExpired = value
    },
    RESET_LOGOUT(state) {
      state.logoutAlert = false
      state.expiredAlert = false
    },
  },
  actions: {
    async FETCH_LOGIN({ commit }, loginBody) {
      const res = await fetchLogin(loginBody)

      if (process.env.NODE_ENV === 'development') {
        console.log(`access_token: ${res.headers.access_token}`)
      }

      localStorage.setItem('access_token', res.headers.access_token)
      localStorage.setItem('login_info', JSON.stringify(res.data.data))

      commit('FETCH_LOGIN_INFO', res.data.data)
      await router.push('/home')
    },
    async SET_EXPIRED({ commit }, err) {
      if (err.response) {
        if (err.response.status === 403) {
          commit('FETCH_EXPIRED', true)
          commit('FETCH_LOGOUT', true)
          localStorage.removeItem('access_token')

          await router.replace('/')

          return commit('FETCH_EXPIRED', false)
        }
      }

      return err
    },
    async FORBID_ACCESS({ commit }) {
      commit('ACCESS_FAIL', true)
      setTimeout(() => {
        commit('ACCESS_FAIL', false)
      }, 2000)
    },
  },
}
