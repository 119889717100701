import { fetchTableDetail } from '@/api/search'

export default {
  state: {
    tableData: {},
    updateTableData: {},
    selectedTableName: 0,
    options: {},
  },
  getters: {},
  mutations: {
    FETCH_CUSTOMER(state, value) {
      Object.assign(state.updateTableData, value)
      console.log(state.updateTableData)
    },
    FETCH_CUSTOMER_DETAIL(state, value) {
      state.tableData = value
    },
  },
  actions: {
    async FETCH_CUSTOMER_DETAIL({ commit, dispatch }, { tableName, id }) {
      const res = await fetchTableDetail(tableName, id)
      console.log(res)
      try {
        return commit('FETCH_CUSTOMER_DETAIL', res.data.data.table_data)
      } catch (e) {
        return dispatch('SET_EXPIRED', res)
      }
    },
  },
}
