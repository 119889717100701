// axios
import axios from 'axios'
import Vue from 'vue'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: `${process.env.VUE_APP_API}`,
  timeout: 30000,
})

axiosIns.interceptors.request.use(
  config => {
    // Do something before request is sent

    const accessToken = localStorage.getItem('access_token')

    // eslint-disable-next-line no-param-reassign
    if (accessToken) config.headers['access-token'] = accessToken

    return config
  },
  error => Promise.reject(error),
)

axiosIns.interceptors.response.use(resp => {
  // eslint-disable-next-line camelcase
  const fe_version = resp.headers['fe-version'] || 'default'
  // eslint-disable-next-line camelcase
  if (fe_version !== localStorage.getItem('fe-version') && resp.config.method === 'get') {
    localStorage.setItem('fe-version', fe_version)
    window.location.reload() // For new version, simply reload on any get
  }

  return Promise.resolve(resp)
})

Vue.prototype.$http = axiosIns

export default axiosIns
