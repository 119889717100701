import axios from '@axios'

export default async function getCustomerCount() {
  try {
    return await axios.get(`${process.env.VUE_APP_API}/v2/customer/count`)
  } catch (e) {
    console.log(e.message)

    return e
  }
}
